/**
 * @author sebastian.qvarfordt@svenskaspel.se (Sebastian Qvarfordt)
 */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import './../../stylesheets/items-menu.less';
import './../../stylesheets/items-menu-compact.less';
import { default as Icon } from 'trinidad-ui/icon';

/**
 * Items menu comes in many flavours. There are currently two types of items supported. Ball-item or link-item.
 * Both have support for icons and can be branded to fulfill the needs for any product.
 * There is build in support for displaying scroll helpers and to scroll active item into view when menu won't fit on the screen.
 * There is built in branding for sport, tur and neutral. But there is also great support to create your own branding.
 */
const ItemsMenu = ({
  inverted,
  branding,
  customer,
  hiddenScrollbar,
  scrollIcons,
  useScrollHelpers,
  scrollToSelected,
  useScrollFaders,
  useScrollFadersOnHover,
  centered,
  compact,
  children,
  className,
  ariaLabel,
  customClass
}) => {
  const wrapperRef = useRef(null);
  const disableLeftFader = React.Children.toArray(children)[0]?.props?.fixedItem;
  let leftIcon;
  let rightIcon;
  let widestItem;
  let isHover = false;

  const renderScroll = () => {
    if (!useScrollFaders) {
      return;
    }

    const leftDimmer = !disableLeftFader ? wrapperRef.current.getElementsByClassName('items-menu-dimmer-left')[0] : false;
    const rightDimmer = wrapperRef.current.getElementsByClassName('items-menu-dimmer-right')[0];
    const items = wrapperRef.current.getElementsByClassName('js-items-menu-item');
    const firstItem = items[0];
    const lastItem = items[items.length - 1];
    const scrollWrapper = wrapperRef.current.getElementsByClassName('items-menu-holder')[0];
    if (!widestItem) {
      for (let i = 0; i < items.length; i++) {
        widestItem = widestItem ? items[i].clientWidth > widestItem ? items[i].clientWidth : widestItem : items[i].clientWidth;
      }
    }
    if (leftDimmer) {
      if (scrollWrapper.scrollLeft < firstItem.clientWidth / 4 || scrollWrapper.scrollLeft === 0) {
        leftDimmer.style.transform = 'scaleX(0)';
      } else {
        if (!useScrollFadersOnHover || (useScrollFadersOnHover && isHover)) {
          leftDimmer.style.transform = 'scaleX(1)';
        }
      }
    }
    if (scrollWrapper.scrollLeft + scrollWrapper.clientWidth >= scrollWrapper.scrollWidth - lastItem.clientWidth / 4) {
      rightDimmer.style.transform = 'scaleX(0)';
    } else {
      if (!useScrollFadersOnHover || (useScrollFadersOnHover && isHover)) {
        rightDimmer.style.transform = 'scaleX(1)';
      }
    }
  };

  const handleDimmerClick = (direction) => {
    const rightDimmer = wrapperRef.current.getElementsByClassName('items-menu-dimmer-right')[0];
    const scrollWrapper = wrapperRef.current.getElementsByClassName('items-menu-holder')[0];
    const scrollBy = widestItem ? widestItem + rightDimmer.clientWidth : 100;
    scrollWrapper.scrollBy({ top: 0, left: direction === 'right' ? scrollBy : -scrollBy, behavior: 'smooth' });
  };

  // check if items is not in view
  const isNotInView = (element) => {
    const rect = element.getBoundingClientRect();
    const wrapperRect = wrapperRef.current.getBoundingClientRect();
    const scrollAreaWidth = wrapperRef.current.querySelector('.js-items-menu-holder').clientWidth;
    const helperWidth = useScrollHelpers ? wrapperRef.current.getElementsByClassName('items-menu-dimmer-left')[0]?.clientWidth : 0;
    return (
      (rect.right + helperWidth) >= scrollAreaWidth ||
      (rect.left - helperWidth) <= wrapperRect.left
    );
  };

  const scrollToSelectedItem = () => {
    if (wrapperRef && wrapperRef.current) {
      const scrollWrapper = wrapperRef.current.getElementsByClassName('items-menu-holder')[0];
      const rightDimmer = useScrollHelpers ? wrapperRef.current.getElementsByClassName('items-menu-dimmer-right')[0] : null;
      const activeEl = wrapperRef.current.getElementsByClassName('items-menu-active')[0];

      if (activeEl && isNotInView(activeEl) && scrollWrapper.scrollBy) {
        const activePos = activeEl.offsetLeft + activeEl.clientWidth + 12 + (useScrollHelpers ? rightDimmer.clientWidth : 0) - scrollWrapper.clientWidth;
        scrollWrapper.scrollBy({
          top: 0, left: activePos, behavior: 'smooth'
        });
      }
    }
  };

  const hideScrollbar = () => {
    const menuWrapper = wrapperRef.current;
    const scrollbarHeight = menuWrapper.clientHeight - menuWrapper.getElementsByClassName('js-items-menu-nav')[0].clientHeight;
    menuWrapper.style.overflow = 'hidden';
    menuWrapper.style.height = `${(menuWrapper.clientHeight - scrollbarHeight)}px`;
  };

  if ((!disableLeftFader && useScrollHelpers) || (!disableLeftFader && scrollIcons && scrollIcons.leftIcon)) {
    const iconLeft = scrollIcons && scrollIcons.leftIcon ? scrollIcons.leftIcon : 'menu-left';
    leftIcon = <span className='items-menu-dimmer-icon items-menu-dimmer-icon-left'><Icon icon={iconLeft} size='100' /></span>;
  }
  if (useScrollHelpers || (scrollIcons && scrollIcons.rightIcon)) {
    const iconRight = scrollIcons && scrollIcons.rightIcon ? scrollIcons.rightIcon : 'menu-right';
    rightIcon = <span className='items-menu-dimmer-icon items-menu-dimmer-icon-right'><Icon icon={iconRight} size='100' /></span>;
  }
  // Scroll to selected item on click.
  const handleChange = (e) => {
    const classList = ['icon-menu-right', 'icon-menu-left', 'js-items-menu-dimmer-right', 'js-items-menu-dimmer-left'];
    if (!classList.some(o => e.target.classList.toString().includes(o))) {
      setTimeout(() => {
        scrollToSelectedItem();
      }, 200);
    }
  };

  const handleResize = () => {
    renderScroll();
    if (hiddenScrollbar) {
      hideScrollbar();
    }
  };

  React.useEffect(() => {
    if (scrollToSelected) {
      scrollToSelectedItem();
    }
    if (hiddenScrollbar) {
      hideScrollbar();
    }
    renderScroll();
    // Set up scrollListener
    if (useScrollHelpers) {
      // Set smaller icons
      if (wrapperRef && wrapperRef.current && wrapperRef.current.clientHeight < 50) {
        Array.prototype.forEach.call(wrapperRef.current.getElementsByClassName('items-menu-dimmer-icon'), (el) => {
          el.classList.add('items-menu-dimmer-icon-small');
        });
      }
      // Use a little bit of throttling.
      const throttle = (fn, wait) => {
        let time = Date.now();
        return () => {
          if ((time + wait - Date.now()) < 0) {
            fn();
            time = Date.now();
          }
        };
      };
      wrapperRef.current.getElementsByClassName('js-items-menu-holder')[0].addEventListener('scroll', throttle(renderScroll, 10));
    }

    window.addEventListener('resize', handleResize);
    let resizeObserver;
    const observeElement = wrapperRef.current.querySelector('.items-menu-nav');

    // Observe for zoom changes (a11y) Progressive won't work on old safari and ie
    if (window.ResizeObserver !== undefined) {
      resizeObserver = new ResizeObserver(() => {
      });
      resizeObserver.observe(observeElement);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
      if (resizeObserver) {
        resizeObserver.unobserve(observeElement);
      }
    };
  }, [hiddenScrollbar, scrollToSelected, useScrollHelpers]);

  const getBrandingClasses = (branding, inverted) => (inverted ? 'items-menu-wrapper-inverted' : `items-menu-wrapper-${branding}`);
  const classNames = [`js-${customClass}-wrapper items-menu-wrapper items-menu-customer-${customer}`];

  if (className) {
    classNames.push(className);
  }
  if (compact) {
    classNames.push('items-menu-compact');
  }
  classNames.push(`${getBrandingClasses(branding, inverted)}`);

  return (
    <div
      className={classNames.join(' ')}
      onClick={handleChange}
      onMouseEnter={() => {
        if (useScrollFadersOnHover) {
          isHover = true;
          renderScroll();
        }
      }}
      onMouseLeave={() => {
        if (useScrollFadersOnHover) {
          isHover = false;
          renderScroll();
        }
      }}
      ref={wrapperRef}
    >
      { disableLeftFader ?
        null :
      (
        <div className="js-items-menu-dimmer-left items-menu-dimmer items-menu-dimmer-left" onClick={() => handleDimmerClick('left')}>
          {leftIcon}
        </div>
      )}
      <div className="js-items-menu-dimmer-right items-menu-dimmer items-menu-dimmer-right" onClick={() => handleDimmerClick('right')}>
        {rightIcon}
      </div>
      <div className={`items-menu-holder js-items-menu-holder js-${customClass} ${centered ? 'items-menu-align-center' : ''}`}>
        <nav aria-label={ariaLabel} className="js-items-menu-nav items-menu-nav">
          {children}
        </nav>
      </div>
    </div>
  );
};

ItemsMenu.propTypes = {
  /**
   * Aria label for the nav-element
   */
  ariaLabel: PropTypes.string,
  /**
   * Branding for items menu ( possible to create your own)
   */
  branding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['neutral', 'tur', 'sport'])
  ]),
  /**
   * If centered
   */
  centered: PropTypes.bool,
  /**
   * Content
   */
  children: PropTypes.node,
  /**
   * Add class
   */
  className: PropTypes.string,
  /**
   * Compact menu
   */
  compact: PropTypes.bool,
  /**
   * Custom class
   */
  customClass: PropTypes.string,
  /**
   * Customer default is neutral
   */
  customer: PropTypes.oneOf(['sport', 'tur', 'neutral']),
  /**
   * Hide the scroll bar
   */
  hiddenScrollbar: PropTypes.bool,
  /**
   * Inverted theme
   */
  inverted: PropTypes.bool,
  /**
   * Any svs icons used as scroll helpers
   */
  scrollIcons: PropTypes.shape({
    leftIcon: PropTypes.string,
    rightIcon: PropTypes.string
  }),
  /**
   * Scroll active item in to viewport
   */
  scrollToSelected: PropTypes.bool,
  /**
   * Use scroll faders for horizontal scroll
   */
  useScrollFaders: PropTypes.bool,
  /**
   * Show scrollfaders only on hover. Use Scrollfaders needs to be true
   */
  useScrollFadersOnHover: PropTypes.bool,
  /**
   * Use scroll helpers for horizontal scroll
   */
  useScrollHelpers: PropTypes.bool
};

ItemsMenu.defaultProps = {
  branding: 'neutral',
  centered: false,
  compact: false,
  customClass: 'default',
  customer: 'neutral',
  hiddenScrollbar: false,
  inverted: false,
  scrollToSelected: true,
  useScrollFadersOnHover: false,
  useScrollHelpers: false
};

export default ItemsMenu;
